import PriceItem from "../PriceItem";
import Button from '../Button';
import './Prices.css';

export default function Prices() {
    return (
        <>
            <div className="prices">
                <h1 className='prices-section-header'>Membership Prices</h1>
                <p className='prices-section-description'>
                    Membership gives you unlimited access to all classes each month and all gradings.
                    All single payments can be paid by cash, card or bank transfer.
                    New comers can sign up for one free trial class.
                </p>
                <div className="prices_container">
                    <div className="prices_wrapper">
                        <ul className="prices_items">
                            <PriceItem
                                header='Free'
                                description='Free Trial Class'
                            />
                            <PriceItem
                                header='£ 75'
                                description='Monthly direct debit'
                            />
                            <PriceItem
                                header='£ 95'
                                description='Single monthly payments'
                            />
                            <PriceItem
                                header='£ 390'
                                description='6 months paid in advance'
                            />
                            <PriceItem
                                header='£ 360'
                                description='6 months paid in advance (Student Discount)'
                            />
                        </ul>
                    </div>
                </div>
                <p className='prices-section-description'>
                    Get your membership or book your free trial class.
                </p>
                <div className="button--join">
                    <Button path={'https://secure12.clubwise.com/bttcambridge/pos.asp'} buttonStyle={'btn--outline'}>Join Now</Button>
                </div>
            </div>
        </>
    );
}